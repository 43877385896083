import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { UserSelf } from '../../../services/data/types/auth'

type UseUserInfoResult = UseQueryResult<AxiosResponse<UserSelf>> & {
  user: UserSelf | null
  isGridsOnlyUser: boolean
  allowManualPayments: boolean
  canDuplicateTrades: boolean
  refreshUser: () => Promise<void>
}

function useUserInfo(): UseUserInfoResult {
  const userQuery = useQuery<AxiosResponse<UserSelf>>({
    queryKey: ['user/self'],
    queryFn: api.getUserSelf,
    refetchOnWindowFocus: false,
  })
  const user = userQuery.data?.data || null

  const isGridsOnlyUser = !!user?.permissions.grids_only
  const allowManualPayments = !!user?.permissions.allow_manual_payment_receipts
  const canDuplicateTrades = !!user?.permissions.can_duplicate_trades

  async function refreshUser() {
    userQuery.remove()
    await userQuery.refetch()
  }

  return { ...userQuery, user, isGridsOnlyUser, allowManualPayments, canDuplicateTrades, refreshUser }
}

export default useUserInfo
