import {
  Add,
  AttachMoney,
  Cancel,
  ContentCopy,
  DisabledByDefault,
  Edit,
  FactCheck,
  FileCopy,
  Lock,
  LockOpen,
  Security,
} from '@mui/icons-material'
import {
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverPosition,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type ContextMenuProps = {
  assetRef?: string | null
  isLocked?: boolean
  hideLockCellButton?: boolean
  canBeLocked?: boolean
  canConfirmTrade?: boolean
  canCorrectTransaction?: boolean
  canCancelTransaction?: boolean
  canDuplicateTrade?: boolean
  canCloseOut?: boolean
  canOnlyApprove?: boolean
  isGridsOnlyUser: boolean
  contextMenuCoordinates: { mouseX: number; mouseY: number } | null
  cancelLabel?: string
  onContextMenuClose: () => void
  onSecuritySelect?: (assetRef: string) => void
  onLockCell?: () => void
  onUnlockCell?: () => void
  onLiquiditySelect?: (assetRef: string) => void
  onPlaceOrder?: () => void
  onConfirmTrade?: () => void
  onCorrectTransaction?: () => void
  onCloseOutTransaction?: () => void
  onClosePosition?: () => void
  onCancelTransaction?: () => void
  onDuplicateTrade?: () => void
  onCopy: () => void
}

function ContextMenu(props: ContextMenuProps) {
  const {
    assetRef,
    isLocked,
    hideLockCellButton,
    canBeLocked,
    canConfirmTrade,
    canCorrectTransaction,
    canCancelTransaction,
    canDuplicateTrade,
    canCloseOut,
    canOnlyApprove,
    isGridsOnlyUser,
    contextMenuCoordinates,
    cancelLabel,
    onContextMenuClose,
    onSecuritySelect,
    onLockCell,
    onUnlockCell,
    onLiquiditySelect,
    onPlaceOrder,
    onConfirmTrade,
    onCorrectTransaction,
    onCloseOutTransaction,
    onClosePosition,
    onCancelTransaction,
    onDuplicateTrade,
    onCopy,
  } = props

  const { t } = useTranslation('portfolio')

  const isOpen = contextMenuCoordinates !== null
  const anchorPosition: PopoverPosition | undefined =
    contextMenuCoordinates !== null
      ? { top: contextMenuCoordinates.mouseY, left: contextMenuCoordinates.mouseX }
      : undefined

  function handleSecurityClick() {
    if (onSecuritySelect && assetRef) {
      onSecuritySelect(assetRef)
      onContextMenuClose()
    }
  }

  function handleLiquidityClick() {
    if (onLiquiditySelect && assetRef) {
      onLiquiditySelect(assetRef)
      onContextMenuClose()
    }
  }

  function handleCloseOutPosition() {
    if (onClosePosition) {
      onClosePosition()
      onContextMenuClose()
    }
  }

  if (isGridsOnlyUser) {
    return (
      <ClickAwayListener onClickAway={onContextMenuClose}>
        <Menu
          open={isOpen}
          onClose={onContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          disableScrollLock
          transitionDuration={{ exit: 0 }}
          sx={{ position: 'static' }}
          PaperProps={{
            sx: { zIndex: 5 },
          }}
          // closeAfterTransition needed because:
          // https://github.com/mui/material-ui/issues/43106
          closeAfterTransition={false}
        >
          <MenuItem onClick={onCopy}>
            <ListItemIcon>
              <ContentCopy sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('common:copy')}</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </ClickAwayListener>
    )
  }

  return (
    <ClickAwayListener onClickAway={onContextMenuClose}>
      <Menu
        open={isOpen}
        onClose={onContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        disableScrollLock
        transitionDuration={{ exit: 0 }}
        sx={{ position: 'static' }}
        PaperProps={{
          sx: { zIndex: 5 },
        }}
        // closeAfterTransition needed because:
        // https://github.com/mui/material-ui/issues/43106
        closeAfterTransition={false}
      >
        {!hideLockCellButton && !isLocked && (
          <MenuItem disabled={!canBeLocked} onClick={onLockCell}>
            <ListItemIcon>
              <Lock sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('lock_cell')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!hideLockCellButton && isLocked && (
          <MenuItem disabled={!canBeLocked} onClick={onUnlockCell}>
            <ListItemIcon>
              <LockOpen sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('unlock_cell')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!onPlaceOrder && (
          <MenuItem onClick={onPlaceOrder}>
            <ListItemIcon>
              <Add sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('place_order')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!onSecuritySelect && (
          <MenuItem onClick={handleSecurityClick}>
            <ListItemIcon>
              <Security sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('security_details.title')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!onLiquiditySelect && (
          <MenuItem onClick={handleLiquidityClick}>
            <ListItemIcon>
              <AttachMoney sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('liquidity')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!canConfirmTrade && !!onConfirmTrade && (
          <MenuItem onClick={onConfirmTrade}>
            <ListItemIcon>
              <FactCheck sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{!!canOnlyApprove ? t('approve_trade') : t('confirm_trade')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!onCorrectTransaction && (
          <Tooltip
            title={canCorrectTransaction ? '' : t('cannot_correct_transaction')}
            placement="top"
            arrow
            disableInteractive
          >
            {/* div is needed or the tooltip is not shown when MenuItem is disabled */}
            <div>
              <MenuItem onClick={onCorrectTransaction} disabled={!canCorrectTransaction}>
                <ListItemIcon>
                  <Edit sx={{ color: 'gray.300' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography fontSize="14px">{t('correct_transaction')}</Typography>
                </ListItemText>
              </MenuItem>
            </div>
          </Tooltip>
        )}

        {!!canCloseOut && !!onCloseOutTransaction && (
          <MenuItem onClick={onCloseOutTransaction}>
            <ListItemIcon>
              <DisabledByDefault sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('close_out_transaction')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!canCloseOut && !!onClosePosition && (
          <MenuItem onClick={handleCloseOutPosition}>
            <ListItemIcon>
              <DisabledByDefault sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('close_position')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!canCancelTransaction && !!cancelLabel && (
          <MenuItem onClick={onCancelTransaction}>
            <ListItemIcon>
              <Cancel sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{cancelLabel}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {!!canDuplicateTrade && !!onDuplicateTrade && (
          <MenuItem onClick={onDuplicateTrade}>
            <ListItemIcon>
              <FileCopy sx={{ color: 'gray.300' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize="14px">{t('duplicate_trade')}</Typography>
            </ListItemText>
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopy sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('common:copy')}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </ClickAwayListener>
  )
}

export default ContextMenu
