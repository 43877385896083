import { Add } from '@mui/icons-material'
import { IconButton, PopoverOrigin } from '@mui/material'
import { Stack, SxProps, Theme } from '@mui/system'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import NestedMenu from '../nested-menu'
import FilterMenuLabel from './filter-menu-label'

type SelectFilterMenuProps = {
  children: ReactNode
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableBackdrop?: boolean
  label?: string
  onAdd?: () => void
  onClose: () => void
  sx?: SxProps<Theme>
}

function SelectFilterMenu(props: SelectFilterMenuProps) {
  const { children, anchorEl, anchorOrigin, transformOrigin, disableBackdrop, label, onAdd, onClose, sx = {} } = props

  const { t } = useTranslation('dataTable')

  return (
    <NestedMenu
      open={!!anchorEl}
      onClose={onClose}
      anchorEl={anchorEl}
      disableBackdrop={disableBackdrop}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
      PaperProps={{
        sx: {
          width: 330,
          maxHeight: '60%',
          ...sx,
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FilterMenuLabel>{label || t('select_value_for_filter')}</FilterMenuLabel>

        {!!onAdd && (
          <IconButton title={t('add_value')} onClick={onAdd} sx={{ mr: 2 }}>
            <Add fontSize="small" sx={{ color: 'gray.300' }} />
          </IconButton>
        )}
      </Stack>
      {children}
    </NestedMenu>
  )
}

export default SelectFilterMenu
