import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg } from '../../../../services/data/types/trade-ticket'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import BooleanSelector from './shared/fields/boolean-selector'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type CloseFxForwardProfileProps = {
  leg: Leg
  staticDataFields: StaticDataFields
}

export function CloseFxForwardProfile(props: CloseFxForwardProfileProps) {
  const { leg, staticDataFields } = props

  const { t } = useTranslation('tradeTicket')

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField value={leg.tradeDirection} disabled sx={{ maxWidth: '150px', mr: 2 }} />

        <NumericField
          disabled
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.baseCurrencySymbol} />}
        />
        <ProfileOperator operator="x" />
        <NumericField
          disabled
          fixedLabel
          name="forward_rate"
          label={t('common:forward_rate')}
          value={leg.forwardRate}
          decimalPlaces={priceInputDecimalPlaces}
        />
        <ProfileOperator operator="=" />
        <NumericField
          disabled
          fixedLabel
          name="quote_amount"
          label={t('common:quote_amount')}
          value={leg.quoteAmount}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.quoteCurrencySymbol} />}
        />
      </ProfileRow>

      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <DateSelector disabled label={t('common:settlement_date')} value={leg.settlementDate} />
        <ProfileOperator />
        <NumericField
          disabled
          fixedLabel
          name="initial_margin_rate"
          label={t('common:initial_margin_rate')}
          value={leg.initialMarginRate}
          decimalPlaces={defaultInputDecimalPlaces}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator />
        <BooleanSelector disabled label={t('common:is_give_up')} value={leg.isGiveUp} />
      </ProfileRow>
    </Stack>
  )
}
