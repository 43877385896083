import { i18n } from '../../../i18n'
import { TradeProfile, TradeTicketResponseAssetType } from '../../../services/data/types/trade-ticket'

export function checkAliasError(staticFields: TradeTicketResponseAssetType['static_fields'], profile: TradeProfile) {
  const isStandardBondProfile = profile === 'standard_bond'
  const isIndexFutureProfile = profile === 'future'

  const contractSize = staticFields.find((sf) => sf.active_alias === 'contract_size')
  const parValue = staticFields.find((sf) => sf.active_alias === 'par_value')
  const contractSizeError =
    isIndexFutureProfile && !contractSize
      ? i18n.t('tradeTicket:missing_asset_static_alias', { alias: 'contract_size' })
      : ''
  const parValueError =
    isStandardBondProfile && !parValue ? i18n.t('tradeTicket:missing_asset_static_alias', { alias: 'par_value' }) : ''

  return contractSizeError || parValueError
}
