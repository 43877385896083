import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PriceUpPayload, StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment, PercentAdorment } from './shared/fields/adorments'
import DateSelector from './shared/fields/date-selector'
import { ProfileExplainer } from './shared/profile-explainer'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'
import { ProfileRowAlert } from './shared/profle-row-alert'

type CdsProfileProps = {
  isCdx?: boolean
  leg: Leg
  staticDataFields: StaticDataFields
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PriceUpPayload, callback?: (leg: Leg) => void) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

type LocalFields =
  | 'accruedInterest'
  | 'initialMarginRate'
  | 'notional'
  | 'price'
  | 'settlementDate'
  | 'spreadBps'
  | 'swapEffectiveDate'
  | 'upfrontCharge'
  | 'value'

export function CdsProfile(props: CdsProfileProps) {
  const {
    isCdx = false,
    leg,
    staticDataFields,
    isTradeConfirmation,
    onUpdateLeg,
    onUpdatePricing,
    onTradeDirectionChange,
  } = props

  const { t } = useTranslation('tradeTicket')

  const [priceBy, setPriceBy] = useState<'price' | 'spread'>(!!leg.price ? 'price' : 'spread')

  // track fields values before a pricing update so the
  // update is triggered only when the values change
  const [previous, setPrevious] = useState({
    accruedInterest: leg.accruedInterest,
    initialMarginRate: leg.initialMarginRate,
    notional: leg.notional,
    price: leg.price,
    settlementDate: leg.settlementDate,
    spreadBps: leg.spreadBps,
    swapEffectiveDate: leg.swapEffectiveDate,
    upfrontCharge: leg.upfrontCharge,
    value: leg.value,
  })

  // when field changes update effect below as well
  const hasAllStaticFields: boolean =
    !!staticDataFields.assetCurrencyAssetRef &&
    !!staticDataFields.maturityDate &&
    !!staticDataFields.issueDate &&
    staticDataFields.coupon !== null &&
    staticDataFields.couponFreq !== null &&
    staticDataFields.recoveryRate != null

  useEffect(() => {
    priceConsideration()
  }, [
    leg.tradeProfile,
    leg.tradeDirection,
    staticDataFields.assetCurrencyAssetRef,
    staticDataFields.maturityDate,
    staticDataFields.issueDate,
    staticDataFields.coupon,
    staticDataFields.couponFreq,
    staticDataFields.recoveryRate,
  ])

  // only trigger a pricing update when the field value changes
  function handlePricingBlur(pricingFn: () => void, field: LocalFields) {
    return function () {
      // converting to string is needed so date fields work
      if (String(previous[field]) !== String(leg[field])) {
        pricingFn()
      }
    }
  }

  function updatePreviousFieldValues(updatedLeg: Leg) {
    setPrevious({
      accruedInterest: updatedLeg.accruedInterest,
      initialMarginRate: updatedLeg.initialMarginRate,
      notional: updatedLeg.notional,
      price: updatedLeg.price,
      settlementDate: updatedLeg.settlementDate,
      spreadBps: updatedLeg.spreadBps,
      swapEffectiveDate: updatedLeg.swapEffectiveDate,
      upfrontCharge: updatedLeg.upfrontCharge,
      value: updatedLeg.value,
    })
  }

  function priceConsideration() {
    const payload = createPricingPayload()

    const priceByPrice = priceBy === 'price' && leg.price
    const priceBySpread = priceBy === 'spread' && leg.spreadBps
    const canPrice = priceByPrice || priceBySpread

    if (!payload || !canPrice) {
      return
    }

    if (priceByPrice) {
      payload.price = Number(leg.price)
      if (leg.spreadBps) {
        payload.proposed_terms.contract_spread = { Float: Number(leg.spreadBps) }
      }
    }

    if (priceBySpread) {
      payload.spread_bps = Number(leg.spreadBps)
      if (leg.price) {
        payload.proposed_terms.contract_price = { Float: Number(leg.price) }
      }
    }

    onUpdatePricing(payload, updatePreviousFieldValues)
  }

  function pricePrice() {
    const payload = createPricingPayload()

    if (!payload || !leg.value) {
      return
    }

    payload.consideration = Number(leg.value)

    onUpdatePricing(payload, updatePreviousFieldValues)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType || !hasAllStaticFields || !leg.notional || !leg.settlementDate) {
      return
    }

    const quantityMultiplier = leg.tradeDirection === 'sell' ? -1 : 1

    const payload: PriceUpPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: Number(leg.notional) * quantityMultiplier,
      price: undefined,
      spread_bps: undefined,
      consideration: undefined,
      static_data: {},
      proposed_terms: {},
    }

    payload.static_data.asset_currency = { AssetRef: staticDataFields.assetCurrencyAssetRef }
    if (staticDataFields.issueDate) {
      payload.static_data.issue_date = { NaiveDate: staticDataFields.issueDate }
    }
    if (staticDataFields.maturityDate) {
      payload.static_data.maturity_date = { NaiveDate: staticDataFields.maturityDate }
    }
    if (staticDataFields.coupon !== null) {
      payload.static_data.coupon = { Percent: staticDataFields.coupon }
    }
    if (staticDataFields.couponFreq !== null) {
      payload.static_data.coupon_freq = { Float: staticDataFields.couponFreq }
    }
    if (staticDataFields.recoveryRate != null) {
      payload.static_data.recovery_rate = { Percent: staticDataFields.recoveryRate }
    }
    if (staticDataFields.dayCount) {
      payload.static_data.day_count = { String: staticDataFields.dayCount }
    }

    payload.proposed_terms.settlement_date = { NaiveDate: formatNaiveDate(leg.settlementDate) }
    if (leg.accruedInterest) {
      payload.proposed_terms.accrued_interest = { Float: Number(leg.accruedInterest) }
    }
    if (leg.swapEffectiveDate) {
      payload.proposed_terms.swap_effective_date = { NaiveDate: formatNaiveDate(leg.swapEffectiveDate) }
    }
    if (leg.initialMarginRate) {
      payload.proposed_terms.initial_margin_rate = { Float: Number(leg.initialMarginRate) / 100 }
    }
    if (leg.tranche) {
      payload.proposed_terms.tranche = { String: leg.tranche }
    }

    return payload
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <ProfileOperator />
        <NumericField
          required
          fixedLabel
          name="notional"
          label={t('common:notional')}
          value={leg.notional}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
          onBlur={handlePricingBlur(priceConsideration, 'notional')}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator operator="@" />
        <FormControl required>
          <InputLabel shrink>{t('price_by')}</InputLabel>
          <Select
            notched
            displayEmpty
            name="price_by"
            label={t('price_by')}
            value={priceBy}
            onChange={(event) => {
              const newPriceBy = event.target.value as 'price' | 'spread'
              setPriceBy(newPriceBy)
              priceConsideration()
            }}
          >
            <MenuItem value="price">{t('common:price')}</MenuItem>
            <MenuItem value="spread">{t('spread')}</MenuItem>
          </Select>
        </FormControl>
        <ProfileOperator />
        {priceBy === 'price' && (
          <NumericField
            fixedLabel
            name="price"
            label={t('common:price')}
            value={leg.price}
            decimalPlaces={priceInputDecimalPlaces}
            onValueChange={(price) => onUpdateLeg({ ...leg, price })}
            onBlur={handlePricingBlur(priceConsideration, 'price')}
            onEnterDown={priceConsideration}
          />
        )}
        {priceBy === 'spread' && (
          <NumericField
            fixedLabel
            name="spread"
            label={t('spread')}
            value={leg.spreadBps}
            decimalPlaces={defaultInputDecimalPlaces}
            onValueChange={(spreadBps) => onUpdateLeg({ ...leg, spreadBps })}
            onBlur={handlePricingBlur(priceConsideration, 'spreadBps')}
            onEnterDown={priceConsideration}
            endAdornment={<InputAdornment position="end">bps</InputAdornment>}
          />
        )}
        <ProfileOperator operator="=" />
        <NumericField
          required
          fixedLabel
          name="upfront_charge"
          label={t('upfront_charge')}
          value={leg.upfrontCharge}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(upfrontCharge) => onUpdateLeg({ ...leg, upfrontCharge })}
          onBlur={handlePricingBlur(priceConsideration, 'upfrontCharge')}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileExplainer title={t('upfront_charge')} message={leg.upfrontExplainer} />
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator operator="+" />
        <NumericField
          fixedLabel
          name="accruedInterest"
          label={t('common:accrued_interest')}
          value={leg.accruedInterest}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(accruedInterest) => onUpdateLeg({ ...leg, accruedInterest })}
          onBlur={handlePricingBlur(priceConsideration, 'accruedInterest')}
          onEnterDown={priceConsideration}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileExplainer title={t('common:accrued_interest')} message={leg.accruedExplainer} />
        <ProfileOperator operator="=" />
        <NumericField
          required
          fixedLabel
          name="value"
          label={t('common:consideration')}
          value={leg.value}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(value) => onUpdateLeg({ ...leg, value })}
          onBlur={handlePricingBlur(pricePrice, 'value')}
          onEnterDown={pricePrice}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
        {priceBy === 'spread' && (
          <NumericField
            fixedLabel
            name="price"
            label={t('common:price')}
            value={leg.price}
            decimalPlaces={priceInputDecimalPlaces}
            onValueChange={(price) => onUpdateLeg({ ...leg, price })}
            onEnterDown={() => {}}
          />
        )}
        {priceBy === 'price' && (
          <NumericField
            fixedLabel
            name="spread"
            label={t('spread')}
            value={leg.spreadBps}
            decimalPlaces={defaultInputDecimalPlaces}
            onValueChange={(spread) => onUpdateLeg({ ...leg, spreadBps: spread })}
            endAdornment={<InputAdornment position="end">bps</InputAdornment>}
            onEnterDown={() => {}}
          />
        )}
      </ProfileRow>
      <ProfileRow>
        <Box sx={{ maxWidth: '150px', mr: 2 }} />

        <ProfileOperator />
        <DateSelector
          required
          fixedLabel
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
          onBlur={handlePricingBlur(priceConsideration, 'settlementDate')}
          onEnterDown={priceConsideration}
        />
        <ProfileOperator />
        <DateSelector
          fixedLabel
          label={t('swap_effective_date')}
          value={leg.swapEffectiveDate}
          onChange={(swapEffectiveDate) => onUpdateLeg({ ...leg, swapEffectiveDate })}
          onBlur={handlePricingBlur(priceConsideration, 'swapEffectiveDate')}
          onEnterDown={priceConsideration}
        />
        <ProfileOperator />
        <NumericField
          fixedLabel
          name="initial_margin_rate"
          label={t('common:initial_margin_rate')}
          value={leg.initialMarginRate}
          decimalPlaces={defaultInputDecimalPlaces}
          onValueChange={(initialMarginRate) => onUpdateLeg({ ...leg, initialMarginRate })}
          onBlur={handlePricingBlur(priceConsideration, 'initialMarginRate')}
          onEnterDown={priceConsideration}
          endAdornment={<PercentAdorment />}
        />
        <ProfileOperator />
        {isCdx ? (
          <FormControl>
            <TextField
              name="tranche"
              label={t('tranche')}
              value={leg.tranche}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              onChange={(event) => onUpdateLeg({ ...leg, tranche: event.target.value })}
            />
          </FormControl>
        ) : (
          <Box />
        )}
      </ProfileRow>
      <ProfileRowAlert error={leg.pricingError} />
    </Stack>
  )
}
