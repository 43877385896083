import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Divider, IconButton, ListItem as MuiListItem, Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import { ReactNode, useState } from 'react'

type ListItemGroupProps = {
  disabled?: boolean
  title: string
  collapsible?: boolean
  divider?: boolean
  isDefaultOpen?: boolean
  secondaryAction?: ReactNode
  children: ReactNode
}

function ListItemGroup(props: ListItemGroupProps) {
  const { disabled, title, collapsible = true, divider = true, isDefaultOpen, secondaryAction, children } = props
  const [isOpen, setIsOpen] = useState(isDefaultOpen ? isDefaultOpen : !collapsible)

  function handleExpand() {
    if (collapsible && !disabled) {
      setIsOpen((isOpen) => !isOpen)
    }
  }

  return (
    <>
      <MuiListItem
        disabled={disabled}
        disablePadding
        secondaryAction={
          <>
            {isOpen && secondaryAction}
            {collapsible && (
              <IconButton disableRipple onClick={handleExpand} sx={{ padding: 0 }} disabled={disabled}>
                {isOpen ? (
                  <ArrowDropUpIcon sx={{ color: 'gray.300', '&:hover': { color: 'white' } }} />
                ) : (
                  <ArrowDropDownIcon sx={{ color: 'gray.300', '&:hover': { color: 'white' } }} />
                )}
              </IconButton>
            )}
          </>
        }
      >
        <ListItemButton
          disabled={disabled}
          onClick={handleExpand}
          disableRipple={!collapsible}
          sx={{
            '&:hover': {
              backgroundColor: collapsible ? 'rgba(255, 255, 255, 0.08)' : 'gray.main',
              cursor: collapsible ? 'pointer' : 'text',
            },
          }}
        >
          <Typography variant="subtitle2" sx={{ marginRight: 'auto' }}>
            {title}
          </Typography>
        </ListItemButton>
      </MuiListItem>
      <Collapse in={isOpen} timeout="auto">
        <List component="div">{children}</List>
      </Collapse>
      {isOpen && divider && <Divider sx={{ mt: 1, mb: 2 }} />}
    </>
  )
}

export default ListItemGroup
