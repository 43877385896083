import { Table, TableBody, TableCell, TableHead, TableProps, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DealTransaction } from '../../../services/data/types/deal'
import { formatTPPercentageValue, formatTPValue, StaticDataFields } from '../../../services/data/types/pricing'
import { TradeProfile, TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import { formatDatetime } from '../../../utils/dates'
import { formatFloat } from '../../../utils/numbers'

type SecurityLegDealTransactionsProps = {
  transactions: DealTransaction[]
  cashflowTypes: TradeTicketResponse['cashflow_types']
  staticDataFields: StaticDataFields
}

export function SecurityLegDealTransactions(props: SecurityLegDealTransactionsProps) {
  const { transactions, cashflowTypes, staticDataFields } = props
  const { assetCurrencySymbol, baseCurrencySymbol, quoteCurrencySymbol } = staticDataFields

  const { t } = useTranslation('tradeTicket')

  const transactionsPerProfile: { [key in TradeProfile]: DealTransaction[] } = {
    standard: [],
    standard_bond: [],
    open_bond_by_spread: [],
    close_bond_by_spread: [],
    standard_repo: [],
    trs: [],
    cds: [],
    cdx: [],
    future: [],
    fx_spot: [],
    fx_forward: [],
    ndf: [],
    cashflow: [],
    // close profiles don't have transactions
    close_repo: [],
    close_fx_forward: [],
    close_ndf: [],
    close_trs: [],
  }
  transactions.forEach((transaction) => {
    transactionsPerProfile[transaction.trade_profile]?.push(transaction)
  })

  return (
    <>
      {!!transactionsPerProfile.standard.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:value')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('common:num_securities')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.price, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.number_of)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.standard_bond.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard_bond.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPPercentageValue(txn.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.accrued_interest, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration, assetCurrencySymbol)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.open_bond_by_spread.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('indicative_value')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.open_bond_by_spread.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.contract_spread, 'bps')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.close_bond_by_spread.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.close_bond_by_spread.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.contract_spread, 'bps')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.standard_repo.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:haircut')}</TableCell>
              <TableCell>{t('common:funding_amount_lc')}</TableCell>
              <TableCell>{t('common:inception_fx')}</TableCell>
              <TableCell>{t('common:repo_funding_amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.standard_repo.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPPercentageValue(txn.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.accrued_interest, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPPercentageValue(txn.trade_particulars.haircut)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.funding_amount_lc)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.inception_fx)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.repo_funding_amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.trs.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:sink_factor')}</TableCell>
              <TableCell>{t('common:clean_price')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:haircut')}</TableCell>
              <TableCell>{t('common:funding_amount_lc')}</TableCell>
              <TableCell>{t('common:inception_fx')}</TableCell>
              <TableCell>{t('common:trs_funding_amount')}</TableCell>
              <TableCell>{t('common:is_fully_funded')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.trs.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPPercentageValue(txn.trade_particulars.sink_factor)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.clean_price, '%')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.accrued_interest, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPPercentageValue(txn.trade_particulars.haircut)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.funding_amount_lc)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.inception_fx)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.trs_funding_amount)}</TableCell>
                <TableCell>{txn.trade_particulars.is_fully_funded ? t('common:yes') : t('common:no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.cds.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
              <TableCell>{t('upfront_charge')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('swap_effective_date')}</TableCell>
              <TableCell>{t('common:initial_margin_rate')}</TableCell>
              <TableCell>{t('tranche')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.cds.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.price, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.spread_bps, 'bps')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.upfront_charge, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.accrued_interest, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration, assetCurrencySymbol)}</TableCell>
                <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{txn.trade_particulars.swap_effective_date || '-'}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.initial_margin_rate, '%')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.cdx.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('spread')}</TableCell>
              <TableCell>{t('upfront_charge')}</TableCell>
              <TableCell>{t('common:accrued_interest')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('swap_effective_date')}</TableCell>
              <TableCell>{t('common:initial_margin_rate')}</TableCell>
              <TableCell>{t('tranche')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.cdx.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.price, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.spread_bps, 'bps')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.upfront_charge, '%')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.accrued_interest, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration, assetCurrencySymbol)}</TableCell>
                <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{txn.trade_particulars.swap_effective_date || '-'}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.initial_margin_rate, '%')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.future.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:price')}</TableCell>
              <TableCell>{t('contract_size')}</TableCell>
              <TableCell>{t('common:num_contracts')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.future.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, assetCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.contract_price)}</TableCell>
                <TableCell>{formatFloat(staticDataFields.contractSize) || '-'}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.number_of)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.fx_spot.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:spot_rate')}</TableCell>
              <TableCell>{t('common:consideration')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('common:is_give_up')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.fx_spot.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.spot_rate)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.consideration)}</TableCell>
                <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{txn.trade_particulars.is_give_up ? t('common:yes') : t('common:no')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.fx_forward.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:forward_rate')}</TableCell>
              <TableCell>{t('common:quote_amount')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.fx_forward.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, baseCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.forward_rate)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.quote_amount, quoteCurrencySymbol)}</TableCell>
                <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.ndf.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:notional')}</TableCell>
              <TableCell>{t('common:ndf_rate')}</TableCell>
              <TableCell>{t('common:quote_amount')}</TableCell>
              <TableCell>{t('common:fixing_date')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
              <TableCell>{t('common:settle_on_quote')}</TableCell>
              <TableCell>{t('common:initial_margin_rate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.ndf.map((txn) => (
              <TableRow key={txn.txn_ref}>
                <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.notional, baseCurrencySymbol)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.ndf_rate)}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.quote_amount, quoteCurrencySymbol)}</TableCell>
                <TableCell>{txn.trade_particulars.fixing_date || '-'}</TableCell>
                <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
                <TableCell>{txn.trade_particulars.is_give_up ? t('common:yes') : t('common:no')}</TableCell>
                <TableCell>{formatTPValue(txn.trade_particulars.initial_margin_rate, '%')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TransactionsTable>
      )}

      {!!transactionsPerProfile.cashflow.length && (
        <TransactionsTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('executed_at')}</TableCell>
              <TableCell>{t('common:cashflow_type')}</TableCell>
              <TableCell>{t('common:amount')}</TableCell>
              <TableCell>{t('common:settlement_date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsPerProfile.cashflow.map((txn) => {
              const cashflowType = cashflowTypes.find((cf) => cf.tag === txn.trade_particulars.cashflow_type)

              return (
                <TableRow key={txn.txn_ref}>
                  <TableCell>{formatDatetime(txn.txn_datetime)}</TableCell>
                  <TableCell>{cashflowType?.name || '-'}</TableCell>
                  <TableCell>{formatTPValue(txn.trade_particulars.quantity)}</TableCell>
                  <TableCell>{txn.trade_particulars.settlement_date || '-'}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </TransactionsTable>
      )}
    </>
  )
}

function TransactionsTable({ children, ...rest }: TableProps) {
  return (
    <Table
      size="small"
      {...rest}
      sx={{
        '& .MuiTableCell-head': { border: 0, pt: 1, pb: 0, fontSize: 12, fontWeight: 400, color: 'gray.300' },
        '& .MuiTableCell-head:first-of-type': { pl: 0 },
        '& .MuiTableCell-head:last-of-type': { pr: 0 },
        '& .MuiTableCell-body': { color: 'white', fontSize: 12 },
        '& .MuiTableCell-body:first-of-type': { pl: 0 },
        '& .MuiTableCell-body:last-of-type': { pr: 0 },
        ...rest.sx,
      }}
    >
      {children}
    </Table>
  )
}
