import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import config from '../../../config'
import SecurityMasterNavigator from '../../../services/data/types/security-master-navigator'
import { DataEngineModule } from './data-engine'

type DataEngineNavProps = {
  open: boolean
  showConfiguration: boolean
  navigator: SecurityMasterNavigator | undefined
  selectedModule: string
  onModuleClick: (title: DataEngineModule, param?: string | number) => void
  onCloseClick: () => void
}

function DataEngineNav(props: DataEngineNavProps) {
  const { open, showConfiguration, navigator, selectedModule, onModuleClick, onCloseClick } = props
  const { t } = useTranslation('dataEngine')

  const { assetTag, classificationId, modelRef } = useParams()

  return (
    <CollapsibleSideNavigation title={t('data_engine')} open={open} onClose={onCloseClick}>
      <ListItemGroup
        title={t('asset_static')}
        divider={config.enableUnreadyFeatures}
        isDefaultOpen={selectedModule === DataEngineModule.Assets || true}
      >
        {navigator?.asset_types.map((asset_type) => (
          <ListItem
            key={asset_type.asset_type_tag}
            title={asset_type.asset_type_name}
            selected={asset_type.asset_type_tag === assetTag}
            onClick={() => onModuleClick(DataEngineModule.Assets, asset_type.asset_type_tag)}
          />
        ))}
      </ListItemGroup>
      {config.enableUnreadyFeatures && (
        <>
          <ListItemGroup
            title={t('classification_static')}
            isDefaultOpen={selectedModule === DataEngineModule.Classifications}
          >
            {navigator?.classifications.map((classification) => (
              <ListItem
                key={classification.classification_id}
                title={classification.classification_name}
                selected={classification.classification_id.toString() === classificationId}
                onClick={() => onModuleClick(DataEngineModule.Classifications, classification.classification_id)}
              />
            ))}
          </ListItemGroup>
          <ListItemGroup
            title={t('models')}
            divider={!config.enableUnreadyFeatures}
            isDefaultOpen={selectedModule === DataEngineModule.Models}
          >
            {navigator?.models.map((model) => (
              <ListItem
                key={model.model_ref}
                title={model.model_name}
                selected={model.model_ref === modelRef}
                onClick={() => onModuleClick(DataEngineModule.Models, model.model_ref)}
              />
            ))}
          </ListItemGroup>
        </>
      )}
      {showConfiguration && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItem
            title={t('configuration')}
            selected={selectedModule === DataEngineModule.Datasets}
            onClick={() => onModuleClick(DataEngineModule.Datasets)}
          />
        </>
      )}
    </CollapsibleSideNavigation>
  )
}

export default DataEngineNav
