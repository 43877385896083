import { Edit } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ConfirmationModal from '../../../components/confirmation-modal'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import config from '../../../config'
import { BaseCurrency } from '../../../services/data/types/portfolio'
import useOpenState from '../../../utils/hooks/use-open-state'
import usePortfoliosQuery from '../data/use-portfolios-query'
import CreateGroupModal from './create-group-modal'
import CreateSandboxModal from './create-sandbox-modal'
import EditGroupModal from './edit-group-modal'
import EditSandboxModal from './edit-sandbox-modal'

type PortfolioGridNavProps = {
  open: boolean
  selectedPortfolioRef: string | null | undefined
  navTitle?: string
  onCloseClick: () => void
  onPortfolioSelect: (portfolioRef: string) => void
}

function PortfolioGridNav(props: PortfolioGridNavProps) {
  const { open, selectedPortfolioRef, navTitle, onCloseClick, onPortfolioSelect } = props

  const { t } = useTranslation('portfolio')

  const portfoliosResponse = usePortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data || null
  const portfolioGroups = [
    { portfolio_group_ref: '1', portfolio_group_name: 'Dummy Group 1', portfolios: [] },
    { portfolio_group_ref: '2', portfolio_group_name: 'Dummy Group 2', portfolios: [] },
  ]
  const portfolioSandboxes = [
    { sandbox_ref: '1', sandbox_name: 'Dummy Sandbox 1', currency: 'EUR' },
    { sandbox_ref: '2', sandbox_name: 'Dummy Sandbox 2', currency: 'USD' },
  ]

  const createGroupModal = useOpenState()
  const editGroupModal = useOpenState()
  const deleteGroupModal = useOpenState()
  const createSandboxModal = useOpenState()
  const editSandboxModal = useOpenState()
  const deleteSandboxModal = useOpenState()

  const [selectedPortfolioGroupRef, setSelectedPortfolioGroupRef] = useState('')
  const [selectedSandboxRef, setSelectedSandboxRef] = useState('')
  const selectedPortfolioGroup = portfolioGroups.find(
    (group) => group.portfolio_group_ref === selectedPortfolioGroupRef
  )
  const selectedSandbox = portfolioSandboxes.find((sandbox) => sandbox.sandbox_ref === selectedSandboxRef)

  function handlePortfolioGroupAction(action: 'select' | 'edit' | 'delete', portfolioGroupRef: string) {
    setSelectedPortfolioGroupRef(portfolioGroupRef)
    if (action === 'edit') {
      editGroupModal.open()
      return
    }

    if (action === 'delete') {
      deleteGroupModal.open()
      return
    }
  }

  function handleSandboxAction(action: 'select' | 'edit' | 'delete', sandboxRef: string) {
    setSelectedSandboxRef(sandboxRef)
    if (action === 'edit') {
      editSandboxModal.open()
      return
    }

    if (action === 'delete') {
      deleteSandboxModal.open()
      return
    }
  }

  return (
    <>
      <CollapsibleSideNavigation title={navTitle || t('navigation.title')} open={open} onClose={onCloseClick}>
        <ListItemGroup isDefaultOpen title={t('navigation.portfolios')}>
          {portfolios?.map((portfolio) => (
            <ListItem
              key={portfolio.portfolio_ref}
              title={portfolio.portfolio_name}
              selected={portfolio.portfolio_ref === selectedPortfolioRef}
              onClick={() => onPortfolioSelect(portfolio.portfolio_ref)}
            />
          ))}
        </ListItemGroup>
        <ListItemGroup
          disabled={!config.enableUnreadyFeatures}
          title={t('navigation.portfolio_groups')}
          secondaryAction={<AddNewButton onClick={createGroupModal.open} />}
        >
          {portfolioGroups?.map((group) => (
            <ListItemWithMenu
              disabled={!config.enableUnreadyFeatures}
              key={group.portfolio_group_ref}
              title={group.portfolio_group_name}
              isSelected={group.portfolio_group_ref === selectedPortfolioGroupRef}
              onClick={() => handlePortfolioGroupAction('select', group.portfolio_group_ref)}
              onEditButtonClick={() => handlePortfolioGroupAction('edit', group.portfolio_group_ref)}
              onDeleteButtonClick={() => handlePortfolioGroupAction('delete', group.portfolio_group_ref)}
            />
          ))}
        </ListItemGroup>
        <ListItemGroup
          disabled={!config.enableUnreadyFeatures}
          title={t('navigation.sandboxes')}
          secondaryAction={<AddNewButton onClick={createSandboxModal.open} />}
        >
          {portfolioSandboxes?.map((sandbox) => (
            <ListItemWithMenu
              disabled={!config.enableUnreadyFeatures}
              key={sandbox.sandbox_ref}
              title={sandbox.sandbox_name}
              isSelected={sandbox.sandbox_ref === selectedSandboxRef}
              onClick={() => handleSandboxAction('select', sandbox.sandbox_ref)}
              onEditButtonClick={() => handleSandboxAction('edit', sandbox.sandbox_ref)}
              onDeleteButtonClick={() => handleSandboxAction('delete', sandbox.sandbox_ref)}
            />
          ))}
        </ListItemGroup>
      </CollapsibleSideNavigation>
      <CreateGroupModal
        open={createGroupModal.isOpen}
        portfolios={portfolios || []}
        onCloseButtonClick={createGroupModal.close}
        onCreateButtonClick={createGroupModal.close}
      />
      <CreateSandboxModal
        open={createSandboxModal.isOpen}
        onCloseButtonClick={createSandboxModal.close}
        onCreateButtonClick={createSandboxModal.close}
      />
      <EditGroupModal
        open={editGroupModal.isOpen}
        portfolios={portfolios || []}
        currentName={selectedPortfolioGroup?.portfolio_group_name || ''}
        currentPortfolios={selectedPortfolioGroup?.portfolios || []}
        onCloseButtonClick={editGroupModal.close}
        onSaveButtonClick={editGroupModal.close}
      />
      <EditSandboxModal
        open={editSandboxModal.isOpen}
        currentName={selectedSandbox?.sandbox_name || ''}
        currentCurrency={(selectedSandbox?.currency as BaseCurrency) || ''}
        onCloseButtonClick={editSandboxModal.close}
        onSaveButtonClick={editSandboxModal.close}
      />
      <ConfirmationModal
        title="Delete Portfolio Group"
        confirmButtonText={t('common:delete')}
        open={deleteGroupModal.isOpen}
        onCloseButtonClick={deleteGroupModal.close}
        onConfirmButtonClick={deleteGroupModal.close}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_you_want_to_delete'}
            values={{ item: selectedPortfolioGroup?.portfolio_group_name || '' }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
      <ConfirmationModal
        title="Delete Sandbox"
        confirmButtonText={t('common:delete')}
        open={deleteSandboxModal.isOpen}
        onCloseButtonClick={deleteSandboxModal.close}
        onConfirmButtonClick={deleteSandboxModal.close}
      >
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey={'common:are_you_sure_you_want_to_delete'}
            values={{ item: selectedSandbox?.sandbox_name || '' }}
            components={{ bold: <strong /> }}
          />
        </Typography>
      </ConfirmationModal>
    </>
  )
}

export default PortfolioGridNav

type AddNewButtonProps = {
  onClick: () => void
}

function AddNewButton(props: AddNewButtonProps) {
  const { onClick } = props

  return (
    <IconButton disableRipple onClick={onClick} sx={{ paddingRight: 0 }}>
      <AddIcon sx={{ color: 'gray.300', '&:hover': { color: 'white' } }} />
    </IconButton>
  )
}

type ListItemWithMenuProps = {
  disabled?: boolean
  title: string
  isSelected: boolean
  onClick: () => void
  onEditButtonClick?: () => void
  onDeleteButtonClick?: () => void
}

function ListItemWithMenu(props: ListItemWithMenuProps) {
  const { disabled, title, isSelected, onClick, onEditButtonClick, onDeleteButtonClick } = props
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const hasMenuActionListener = onEditButtonClick || onDeleteButtonClick

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleEdit() {
    handleClose()
    onEditButtonClick!()
  }

  function handleDelete() {
    handleClose()
    onDeleteButtonClick!()
  }

  return (
    <ListItem
      disabled={disabled}
      title={title}
      selected={isSelected}
      sx={{ '&:hover .menu-button': { opacity: 1 } }}
      secondaryAction={
        hasMenuActionListener ? (
          <>
            <IconButton
              disableRipple
              onClick={openMenu}
              className="menu-button"
              sx={{ padding: 0, opacity: anchorEl ? 1 : 0 }}
            >
              <MoreVertIcon sx={{ color: 'gray.300', '&:hover': { color: 'white' } }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              {onEditButtonClick && (
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <Edit fontSize="small" sx={{ color: 'gray.300' }} />
                  </ListItemIcon>
                  <ListItemText>{t('common:edit')}</ListItemText>
                </MenuItem>
              )}
              {onDeleteButtonClick && (
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" sx={{ color: 'gray.300' }} />
                  </ListItemIcon>
                  <ListItemText>{t('common:delete')}</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <div style={{ width: '40px' }}></div>
        )
      }
      onClick={onClick}
    />
  )
}
