import { Box, Dialog, FormControl, Alert as MuiAlert, Stack, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/alert'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import ModalTitle from '../../../components/modal-title'
import { convertSelectedDateToUtc } from '../../../services/data/convert-time'
import { queryClient } from '../../../services/data/react-query'
import { UploadModelPayload } from '../../../services/data/types/models'
import { datetimeFormat, datetimePlaceholder } from '../../../utils/dates'
import useUploadModelMutation from '../data/use-upload-model-mutation'

type UploadModelProps = {
  name: string
  datasetName: string
  classificationName: string | null
  modelRef: string
  isOpen: boolean
  onClose: () => void
  setSelectedVersion: (version: string) => void
}

function UploadModel(props: UploadModelProps) {
  const { name, datasetName, classificationName, modelRef, isOpen, onClose, setSelectedVersion } = props

  const { t } = useTranslation('dataEngine')

  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const [selectedFile, setSelectedFile] = useState<File>()

  const uploadModel = useUploadModelMutation()

  const currentModelBasis = classificationName
    ? `${classificationName} (${datasetName})`
    : t('models_table.asset_based')
  const infoText = classificationName
    ? t('upload_model_modal.classification_based')
    : t('upload_model_modal.asset_based')

  useEffect(() => {
    if (isOpen) {
      setSelectedDate(new Date())
    }
  }, [isOpen])

  function handleDateChange(date: Date | null) {
    const isValidDate = date && !isNaN(date.getTime())

    if (isValidDate) {
      date.setSeconds(0)
      setSelectedDate(date)
    } else {
      setSelectedDate(null)
    }
  }

  function handleClose() {
    uploadModel.reset()
    setSelectedFile(undefined)
    onClose()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!modelRef || !selectedDate || !selectedFile) {
      return
    }

    const payload: UploadModelPayload = {
      modelRef: modelRef,
      date: convertSelectedDateToUtc(selectedDate),
      file: selectedFile,
    }

    uploadModel.mutate(payload, {
      onSuccess: async () => {
        handleClose()
        await queryClient.invalidateQueries(['models', modelRef, 'model'])
        setSelectedVersion(convertSelectedDateToUtc(selectedDate))
      },
    })
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalTitle title={t('upload_model_modal.modal_title')} onClose={handleClose} />
        <ModalContent>
          <Alert message={uploadModel.error} severity="error" />
          <ModelDetailRow label="Name" value={name} />
          <ModelDetailRow label="Basis" value={currentModelBasis} />
          <MuiAlert
            severity="info"
            variant="filled"
            sx={{
              py: 1,
              px: 2,
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.08)',
            }}
          >
            <Typography variant="caption">{infoText}</Typography>
          </MuiAlert>
          <Stack direction="row">
            <CustomFileInputField selectedFile={selectedFile} onFileChange={setSelectedFile} />
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  inputFormat={datetimeFormat}
                  label={t('upload_model_modal.date_picker_label')}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      required
                      fullWidth
                      autoComplete="off"
                      inputProps={{
                        ...props.inputProps,
                        placeholder: datetimePlaceholder,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Stack>
        </ModalContent>
        <ModalActions
          confirmDisabled={!modelRef || !selectedDate || !selectedFile}
          isLoading={uploadModel.isLoading}
          confirmLabel={t('common:upload')}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

type ModelDetailRowProps = {
  label: string
  value: string
}

function ModelDetailRow(props: ModelDetailRowProps) {
  const { label, value } = props

  return (
    <Stack direction="row" marginLeft="12px">
      <Typography variant="caption" sx={{ color: 'gray.300', lineHeight: 2, flex: 1 }}>
        {label}
      </Typography>
      <Typography sx={{ flex: 1 }}>{value}</Typography>
    </Stack>
  )
}

type CustomFileInputFieldProps = {
  selectedFile?: File
  onFileChange: (file: File) => void
}

function CustomFileInputField(props: CustomFileInputFieldProps) {
  const { selectedFile, onFileChange } = props

  const { t } = useTranslation('dataEngine')

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files
    const file = files?.[0]

    if (file) {
      onFileChange(file)
    }
  }

  return (
    <Box
      sx={{
        width: '280px',
        height: '55px',
        position: 'relative',
        border: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(255, 255, 255, 0.25)',
        borderRadius: '4px',
        lineHeight: 1,
        marginRight: '16px',
        paddingLeft: '12px',
        overflow: 'hidden',
        '&:hover': {
          borderColor: 'white',
        },
      }}
    >
      <p>{selectedFile?.name || t('common:select_file')}</p>
      <input
        onChange={handleFileChange}
        type="file"
        accept=".xls,.xlsx,.xlsm"
        style={{
          margin: 0,
          opacity: '0',
          fontSize: '0px',
          width: '280px',
          height: '55px',
          position: 'absolute',
          top: '0px',
          left: '0px',
          cursor: 'pointer',
        }}
      />
    </Box>
  )
}

export default UploadModel
