import { Menu, PaperProps, PopoverOrigin, SxProps } from '@mui/material'
import { ReactNode } from 'react'

type NestedMenuProps = {
  open: boolean
  anchorEl?: HTMLElement | null
  children?: ReactNode
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableBackdrop?: boolean
  PaperProps?: PaperProps
  sx?: SxProps
  onClose?: () => void
}

function NestedMenu(props: NestedMenuProps) {
  const { open, anchorEl, children, anchorOrigin, transformOrigin, disableBackdrop, PaperProps, sx, onClose } = props

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      PaperProps={PaperProps}
      sx={{
        ...sx,
        pointerEvents: disableBackdrop ? 'none' : 'initial',
        '& .MuiPaper-root': {
          pointerEvents: 'auto',
        },
      }}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      {children}
    </Menu>
  )
}

export default NestedMenu
