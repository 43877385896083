import { DeleteOutline } from '@mui/icons-material'
import { IconButton, Stack, TextField } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ParsedFilter } from '../../services/data/filter-parsing'
import { GridDataViewFilters } from '../../services/data/types/grid-data-view'
import FilterFormButtons from './filter-form-buttons'
import SelectFilterMenu from './select-filter-menu'

type StringFilterMenuProps = {
  datapointRef: string
  disableBackdrop?: boolean
  selectedFilter?: ParsedFilter
  anchorEl: HTMLElement | null
  isSelectedFilter: boolean
  onClose: () => void
  onChange: (filters: GridDataViewFilters) => void
  onDelete: () => void
}

export function StringFilterMenu(props: StringFilterMenuProps) {
  const { datapointRef, selectedFilter, anchorEl, disableBackdrop, isSelectedFilter, onChange, onClose, onDelete } =
    props

  const { t } = useTranslation('dataTable')

  const [values, setValues] = useState<string[]>([''])

  const canSubmit = !!values.length && values.some((value) => value.trim() !== '')
  const canClear = isSelectedFilter

  useEffect(() => {
    const initial = selectedFilter?.values.map((value) => String(value)) || ['']
    setValues(initial)
  }, [selectedFilter, anchorEl])

  function handleAddValue() {
    setValues(values.concat(['']))
  }

  function handleRemoveValue(index: number) {
    setValues(values.filter((_, vIndex) => vIndex !== index))
  }

  function handleUpdateValue(index: number, value: string) {
    setValues(
      values.map((v, vIndex) => {
        if (vIndex === index) {
          return value
        }
        return v
      })
    )
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    onChange({
      [datapointRef]: {
        operator: 'contains',
        values: values.filter((value) => value.trim() !== ''),
      },
    })
    onClose()
  }

  return (
    <SelectFilterMenu
      anchorEl={anchorEl}
      disableBackdrop={disableBackdrop}
      label={t('filter_for_values_containing')}
      onAdd={handleAddValue}
      onClose={onClose}
      sx={{ ml: -1.5 }}
    >
      <form onSubmit={handleSubmit}>
        <Stack gap={1} sx={{ m: 2 }}>
          {values.map((value, index) => {
            return (
              <Stack key={index} direction="row" alignItems="center" gap={1}>
                <TextField
                  autoFocus={index === 0}
                  autoComplete="off"
                  size="small"
                  value={value}
                  onChange={(event) => handleUpdateValue(index, event.target.value)}
                  sx={{ flex: 1 }}
                />

                {values.length > 1 && (
                  <IconButton title={t('remove_value')} onClick={() => handleRemoveValue(index)}>
                    <DeleteOutline fontSize="small" sx={{ color: 'gray.300' }} />
                  </IconButton>
                )}
              </Stack>
            )
          })}
        </Stack>

        <FilterFormButtons
          submitDisabled={!canSubmit}
          clearDisabled={!canClear}
          onCancel={onClose}
          onClear={onDelete}
        />
      </form>
    </SelectFilterMenu>
  )
}
