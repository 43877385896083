import { InfoOutlined } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { Box, lighten, Stack, useTheme } from '@mui/system'

type ProfileExplainerProps = {
  title: string
  message: string
}

export function ProfileExplainer(props: ProfileExplainerProps) {
  const { title, message } = props

  const theme = useTheme()

  if (!message) {
    return null
  }

  return (
    <Box sx={{ flex: 0, alignSelf: 'center', mx: 1 }}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              // same as info in src/components/alert-icon.tsx
              color: lighten(theme.palette.info.dark, 0.6),
              backgroundColor: '#07202E',
              border: `1px solid ${theme.palette.info.dark}`,
              alignItems: 'center',
              maxWidth: 'none',
              maxHeight: '50vh',
              overflowY: 'auto',
              padding: 1,
              whiteSpace: 'pre-wrap',
            },
          },
        }}
        title={
          <Stack>
            <Typography variant="subtitle1" color="inherit" mr="auto" mb={1.5}>
              {title}
            </Typography>
            <Typography variant="inherit" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
              {message}
            </Typography>
          </Stack>
        }
      >
        {/* needed to align vertically with ProfileOperator when seen together */}
        <Box height="20px" pt="1px">
          <InfoOutlined fontSize="small" sx={{ color: 'gray.300' }} />
        </Box>
      </Tooltip>
    </Box>
  )
}
