import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import parseError from '../../../services/data/parse-error'

function useDuplicateTransactionMutation() {
  const response = useMutation<AxiosResponse, AxiosError, { txnRef: string }>({
    mutationFn: ({ txnRef }) => api.duplicateTransaction(txnRef),
  })

  return { ...response, error: parseError(response.error) }
}

export default useDuplicateTransactionMutation
