import Alert from '../../../../../components/alert'
import { ProfileRow } from './profile-row'

type ProfileRowAlertProps = {
  error: string | null
}

export function ProfileRowAlert(props: ProfileRowAlertProps) {
  if (!props.error) {
    return null
  }

  return (
    <ProfileRow>
      <Alert severity="error" message={props.error} />
    </ProfileRow>
  )
}
