import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PriceUpPayload, StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { defaultInputDecimalPlaces, priceInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment } from './shared/fields/adorments'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type IndexFutureProfileProps = {
  leg: Leg
  staticDataFields: StaticDataFields
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PriceUpPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function IndexFutureProfile(props: IndexFutureProfileProps) {
  const { leg, staticDataFields, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    priceNumberOf()
  }, [leg.tradeProfile, staticDataFields.contractSize])

  function priceNumberOf() {
    const pricingPayload = createPricingPayload()

    if (!pricingPayload || !leg.price || !leg.notional) {
      return
    }

    pricingPayload.quantity_type = 'notional'
    pricingPayload.quantity = Number(leg.notional)
    pricingPayload.price = Number(leg.price)

    onUpdatePricing(pricingPayload)
  }

  function priceNotional() {
    const pricingPayload = createPricingPayload()

    if (!pricingPayload || !leg.price || !leg.numberOf) {
      return
    }

    pricingPayload.quantity_type = 'number_of'
    pricingPayload.quantity = Number(leg.numberOf)
    pricingPayload.price = Number(leg.price)

    onUpdatePricing(pricingPayload)
  }

  function createPricingPayload() {
    if (!leg.tradeAssetType || staticDataFields.contractSize === null) {
      return
    }

    const payload: PriceUpPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: 'notional',
      quantity: undefined,
      price: undefined,
      spread_bps: undefined,
      consideration: undefined,
      static_data: {
        contract_size: { Float: staticDataFields.contractSize },
      },
      proposed_terms: {},
    }

    return payload
  }

  return (
    <ProfileRow>
      <TradeDirectionField
        value={leg.tradeDirection}
        disabled={isTradeConfirmation}
        onChange={onTradeDirectionChange}
        sx={{ maxWidth: '150px', mr: 2 }}
      />

      <NumericField
        required
        fixedLabel
        name="notional"
        label={t('common:notional')}
        value={leg.notional}
        decimalPlaces={defaultInputDecimalPlaces}
        onValueChange={(notional) => onUpdateLeg({ ...leg, notional })}
        onBlur={priceNumberOf}
        onEnterDown={priceNumberOf}
        endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
      />
      <ProfileOperator operator="=" />
      <NumericField
        required
        fixedLabel
        name="price"
        label={t('common:price')}
        value={leg.price}
        decimalPlaces={priceInputDecimalPlaces}
        onValueChange={(price) => onUpdateLeg({ ...leg, price })}
        onBlur={priceNumberOf}
        onEnterDown={priceNumberOf}
      />
      <ProfileOperator operator="x" />
      <NumericField
        required
        disabled
        fixedLabel
        name="contractSize"
        label={t('contract_size')}
        value={String(staticDataFields.contractSize ?? '')}
        decimalPlaces={defaultInputDecimalPlaces}
      />
      <ProfileOperator operator="x" />
      <NumericField
        required
        fixedLabel
        name="numberOf"
        label={t('common:num_contracts')}
        value={leg.numberOf}
        decimalPlaces={defaultInputDecimalPlaces}
        onValueChange={(numberOf) => onUpdateLeg({ ...leg, numberOf })}
        onBlur={priceNotional}
        onEnterDown={priceNotional}
      />
    </ProfileRow>
  )
}
