import { useTranslation } from 'react-i18next'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import { OrderBatchHeader } from '../../../services/data/types/order-batch'

type OrderBlotterNavProps = {
  orderBatches: OrderBatchHeader[] | null
  selectedOrderBatchRef: string | undefined
  isNavOpen: boolean
  onNavClose: () => void
  onOrderBatchSelect: (orderBatchRef: string) => void
}

export function OrderBlotterNav(props: OrderBlotterNavProps) {
  const { orderBatches, selectedOrderBatchRef, isNavOpen, onNavClose, onOrderBatchSelect } = props

  const { t } = useTranslation('orderBlotter')

  return (
    <CollapsibleSideNavigation open={isNavOpen} title={t('nav_title')} onClose={onNavClose}>
      <ListItemGroup title={t('order_batches')} isDefaultOpen collapsible={false} divider={false}>
        {orderBatches?.map((orderBatch) => {
          return (
            <ListItem
              key={orderBatch.order_batch_ref}
              selected={orderBatch.order_batch_ref === selectedOrderBatchRef}
              title={orderBatch.order_batch_name}
              onClick={() => onOrderBatchSelect(orderBatch.order_batch_ref)}
            />
          )
        })}
      </ListItemGroup>
    </CollapsibleSideNavigation>
  )
}
