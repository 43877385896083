import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    gray: Palette['primary'] & Palette['grey']
    purple: Palette['primary']
    arc: {
      info: string
    }
  }
  interface PaletteOptions {
    gray: PaletteOptions['primary']
    purple: PaletteOptions['primary']
    arc: {
      info: string
    }
  }
  interface Components {
    MuiCalendarPicker?: Components['MuiPopover']
  }
}

export const muiTheme = createTheme({
  palette: {
    mode: 'dark',
    arc: {
      info: '#41AAB6',
    },
    primary: {
      main: '#78DCE8',
      dark: 'rgba(120, 220, 232, 0.8)',
      light: 'rgba(120, 220, 232, 0.08)',
    },
    error: {
      main: '#E57373',
      dark: '#E26768',
      '500': '#F44336',
    },
    success: {
      main: '#66BB6A',
    },
    gray: {
      main: '#1A202C',
      light: '#2D3748',
      '50': 'rgba(255, 255, 255, 0.05)',
      '200': 'rgba(255, 255, 255, 0.3)',
      '300': 'rgba(255, 255, 255, 0.56)',
      '400': '#A0AEC0',
      '700': 'rgba(255, 255, 255, 0.7)',
    },
    purple: {
      main: '#AE81FF',
      '400': '#9F7AEA',
    },
    background: { default: '#4A5568' },
    divider: 'rgba(255, 255, 255, 0.12)',
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.gray['300'],
          '.MuiSvgIcon-root': {
            color: theme.palette.gray['300'],
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          textTransform: 'none',
          '&.MuiButton-contained': {
            '&:disabled': {
              backgroundColor: '#81E6D9',
              opacity: 0.4,
              color: 'black',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.gray.main,
            backgroundImage: 'none',
            border: '1px solid rgba(255, 255, 255, 0.23)',
            boxShadow:
              '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
            borderRadius: '4px',
            minWidth: '276px',
          },
          '& .MuiListSubheader-root': {
            backgroundColor: theme.palette.gray.main,
            textTransform: 'uppercase',
          },
          '& .MuiMenuItem-root': {
            borderRadius: 4,
            marginLeft: 8,
            marginRight: 8,
            paddingRight: 8,
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.gray.main,
            backgroundImage: 'none',
            width: '575px',
          },
          '& .MuiDialogContentText': {
            color: 'white',
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
          '& .MuiButton-root': {
            textTransform: 'none',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&::-webkit-scrollbar': {
            width: '18px',
            height: '18px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '9px',
            borderWidth: '4px',
          },
          '&.MuiPaper-root': {
            padding: '24px',
            backgroundColor: theme.palette.gray.main,
            backgroundImage: 'none',
          },
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderCollapse: 'separate',
          '.MuiTableCell-root': {
            backgroundColor: '#1A202C',
          },
          '.MuiTableBody-root .MuiTableRow-root:not(.selected-row) .MuiTableCell-root': {
            backgroundColor: '#1A202C',
          },
          '&.grid .MuiTableCell-head, .MuiTableCell-stickyHeader': {
            backgroundColor: '#252B37',
          },
          '.MuiTableRow-root.selected-row': {
            '.MuiTableCell-root': {
              backgroundColor: '#252B37',
              color: '#78DCE8',
            },
            '.MuiTableCell-root:first-of-type': {
              borderLeft: '2px solid #ADFFFF',
            },
          },
          '&.grid-freeze': {
            '.MuiTableCell-head': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '.MuiTableCell-head:first-of-type': {
              left: 0,
              zIndex: 2,
            },
            '.MuiTableCell-body:first-of-type': {
              position: 'sticky',
              left: 0,
              zIndex: 1,
            },
          },
          '&.grid .MuiTableRow-root': {
            lineHeight: '0px',
            height: '0px',
            '.MuiTableCell-root': {
              cursor: 'default',
            },
          },
          '&.grid .MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root': {
            borderBottom: 'none',
          },
          '&.grid .MuiTableCell-root': {
            fontSize: '14px',
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            color: 'white',
            padding: 0,
            margin: 0,
            borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
            borderRadius: 0,
            maxHeight: '32px',
          },
          '&.grid .MuiTableCell-root:last-of-type': {
            borderRight: 'none',
          },
          '&.grid .MuiTableHead-root .MuiTableCell-root': {
            padding: '10px 16px',
            lineHeight: '24px',
            fontWeight: 500,
          },
          '&:not(.grid) .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
            borderBottom: 'solid 1px',
            borderColor: theme.palette.divider,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'transparent',
            '& .MuiTableCell-root': {
              color: 'white',
              backgroundColor: 'rgba(120, 220, 232, 0.08)',
            },
            '& .action-cell': {
              opacity: 1,
            },
          },
          '&.MuiTableRow-hover.active': {
            '& .MuiTableCell-root': {
              color: 'white',
              backgroundColor: 'rgba(120, 220, 232, 0.08)',
            },
            '& .action-cell': {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: ({ theme }) => ({
          ...theme.typography.body1,
          padding: '8px 12px',
          borderBottom: 'none',
          color: theme.palette.gray[700],
          '&.action-cell': {
            opacity: 0,
          },
          '&:first-of-type': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          },
          '&:last-of-type': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },
          '& .MuiIconButton-root': {
            color: theme.palette.gray[300],
          },
        }),
        head: {
          padding: '16px 12px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-outlined': {
            backgroundColor: 'transparent',
          },
          '&.MuiChip-filled.dark': {
            backgroundColor: 'rgba(26, 32, 44, 0.6)',
          },
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: '#2D3748',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.gray.main,
          backgroundImage: 'none',
          border: '1px solid rgba(255, 255, 255, 0.23)',
          boxShadow:
            '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        }),
      },
    },
  },
  typography: {
    allVariants: {
      color: 'white',
    },
    fontFamily: 'Roboto, sans-serif',
  },
})
