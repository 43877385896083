import { Box, InputAdornment, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../../components/fields/numeric-field'
import { TradeDirectionField } from '../../../../components/fields/trade-direction-field'
import { PriceUpPayload, StaticDataFields } from '../../../../services/data/types/pricing'
import { Leg, TradeDirection } from '../../../../services/data/types/trade-ticket'
import { formatNaiveDate } from '../../../../utils/dates'
import { defaultInputDecimalPlaces } from '../../../../utils/numbers'
import { CurrencyAdorment } from './shared/fields/adorments'
import DateSelector from './shared/fields/date-selector'
import { ProfileOperator } from './shared/profile-operator'
import { ProfileRow } from './shared/profile-row'

type OpenBondBySpreadProfileProps = {
  leg: Leg
  staticDataFields: StaticDataFields
  isTradeConfirmation: boolean
  onUpdateLeg: (leg: Leg) => void
  onUpdatePricing: (payload: PriceUpPayload) => void
  onTradeDirectionChange: (tradeDirection: TradeDirection) => void
}

export function OpenBondBySpreadProfile(props: OpenBondBySpreadProfileProps) {
  const { leg, staticDataFields, isTradeConfirmation, onUpdateLeg, onUpdatePricing, onTradeDirectionChange } = props

  const { t } = useTranslation('tradeTicket')

  useEffect(() => {
    updatePrices()
  }, [leg.tradeProfile, staticDataFields.parValue])

  function updatePrices() {
    if (!leg.tradeAssetType || !leg.value || !leg.spreadBps) {
      return
    }

    const payload: PriceUpPayload = {
      asset_type: leg.tradeAssetType.asset_type_tag,
      quantity_type: undefined,
      quantity: undefined,
      price: undefined,
      spread_bps: undefined,
      consideration: Number(leg.value),
      static_data: {},
      proposed_terms: {
        contract_spread: { Float: Number(leg.spreadBps) },
      },
    }

    if (staticDataFields.parValue !== null) {
      payload.static_data.par_value = { Float: staticDataFields.parValue }
    }

    if (leg.settlementDate) {
      payload.proposed_terms.settlement_date = { NaiveDate: formatNaiveDate(leg.settlementDate) }
    }

    onUpdatePricing(payload)
  }

  return (
    <Stack gap={3}>
      <ProfileRow>
        <TradeDirectionField
          value={leg.tradeDirection}
          disabled={isTradeConfirmation}
          onChange={onTradeDirectionChange}
          sx={{ maxWidth: '150px', mr: 2 }}
        />

        <NumericField
          required
          fixedLabel
          name="indicativeValue"
          label={t('indicative_value')}
          value={leg.value}
          decimalPlaces={defaultInputDecimalPlaces}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          onValueChange={(value) => onUpdateLeg({ ...leg, value })}
          endAdornment={<CurrencyAdorment symbol={staticDataFields.assetCurrencySymbol} />}
        />
        <ProfileOperator operator="" />
        <NumericField
          required
          fixedLabel
          name="spread"
          label={t('spread')}
          value={leg.spreadBps}
          decimalPlaces={defaultInputDecimalPlaces}
          onBlur={updatePrices}
          onEnterDown={updatePrices}
          onValueChange={(spreadBps) => onUpdateLeg({ ...leg, spreadBps })}
          endAdornment={<InputAdornment position="end">bps</InputAdornment>}
        />
      </ProfileRow>
      <ProfileRow>
        <DateSelector
          required
          label={t('common:settlement_date')}
          value={leg.settlementDate}
          onChange={(settlementDate) => onUpdateLeg({ ...leg, settlementDate })}
        />
        <ProfileOperator />
        <Box />
        <ProfileOperator />
        <Box />
      </ProfileRow>
    </Stack>
  )
}
