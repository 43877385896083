import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import config from '../../../config'
import { UserSelf } from '../../../services/data/types/auth'
import { SettingsModule } from './settings'

type SettingsNavProps = {
  open: boolean
  user: UserSelf | null
  selectedModule: string
  onModuleClick: (title: SettingsModule) => void
  onCloseClick: () => void
}

function SettingsNav(props: SettingsNavProps) {
  const { open, user, selectedModule, onModuleClick, onCloseClick } = props
  const { t } = useTranslation('settings')

  return (
    <CollapsibleSideNavigation title={t('settings')} open={open} onClose={onCloseClick}>
      <ListItemGroup title={t('tenant_admin')} collapsible={false} divider={!!user?.is_superuser}>
        <ListItem
          disabled={!config.enableUnreadyFeatures}
          title={t('tenant')}
          selected={selectedModule === SettingsModule.Tenant}
          onClick={() => onModuleClick(SettingsModule.Tenant)}
        />
        <ListItem
          title={t('users')}
          selected={selectedModule === SettingsModule.Users}
          onClick={() => onModuleClick(SettingsModule.Users)}
        />
        <ListItem
          title={t('groups_title')}
          selected={selectedModule === SettingsModule.Groups}
          onClick={() => onModuleClick(SettingsModule.Groups)}
        />
        <ListItem
          disabled={!config.enableUnreadyFeatures}
          title={t('portfolios')}
          selected={selectedModule === SettingsModule.Portfolios}
          onClick={() => onModuleClick(SettingsModule.Portfolios)}
        />
        <ListItem
          title={t('views')}
          selected={selectedModule === SettingsModule.Views}
          onClick={() => onModuleClick(SettingsModule.Views)}
        />
        <ListItem
          disabled={!config.enableUnreadyFeatures}
          title={t('compliance')}
          selected={selectedModule === SettingsModule.Compliance}
          onClick={() => onModuleClick(SettingsModule.Compliance)}
        />
        <ListItem
          disabled={!config.enableUnreadyFeatures}
          title={t('counterparties')}
          selected={selectedModule === SettingsModule.Counterparties}
          onClick={() => onModuleClick(SettingsModule.Counterparties)}
        />
        <ListItem
          disabled={!config.enableUnreadyFeatures}
          title={t('general_ledger')}
          selected={selectedModule === SettingsModule.GeneralLedger}
          onClick={() => onModuleClick(SettingsModule.GeneralLedger)}
        />
        <ListItem
          title={t('audit_trail')}
          selected={selectedModule === SettingsModule.AuditTrail}
          onClick={() => onModuleClick(SettingsModule.AuditTrail)}
        />
      </ListItemGroup>

      {user?.is_superuser && (
        <Stack mt={1.5}>
          <ListItemGroup title={t('system_admin')} collapsible={false}>
            <ListItem
              title={t('tenants')}
              selected={selectedModule === SettingsModule.Tenants}
              onClick={() => onModuleClick(SettingsModule.Tenants)}
            />
            <ListItem
              title={t('legal_entities')}
              selected={selectedModule === SettingsModule.LegalEntities}
              onClick={() => onModuleClick(SettingsModule.LegalEntities)}
            />
            <ListItem
              title={t('non_tenanted_users')}
              selected={selectedModule === SettingsModule.NonTenantedUsers}
              onClick={() => onModuleClick(SettingsModule.NonTenantedUsers)}
            />
            <ListItem
              title={t('chains')}
              selected={selectedModule === SettingsModule.Chains}
              onClick={() => onModuleClick(SettingsModule.Chains)}
            />
          </ListItemGroup>
        </Stack>
      )}
    </CollapsibleSideNavigation>
  )
}

export default SettingsNav
