import { Add, Close, Replay, Save, SaveAs } from '@mui/icons-material'
import { Chip, ClickAwayListener, Divider, IconButton, List, MenuList, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../components/confirmation-modal'
import { MenuItemButton } from '../../../components/menu-item-button'
import { MenuItemLabel } from '../../../components/menu-item-label'
import { queryClient } from '../../../services/data/react-query'
import { StaticOptionsResponse } from '../../../services/data/types/asset-static-data'
import { GridDataDataset } from '../../../services/data/types/grid-data'
import {
  GridDataView,
  GridDataViewCreateBody,
  GridDataViewUpdateBody,
} from '../../../services/data/types/grid-data-view'
import { Group } from '../../../services/data/types/group'
import { ViewListItem } from '../../../services/data/types/view'
import useOpenState from '../../../utils/hooks/use-open-state'
import QuickSearchField from '../../data-engine/components/quick-search-field'
import useCreateGridDataViewMutation from '../data/use-create-grid-data-view-mutation'
import useDeleteGridDataViewMutation from '../data/use-delete-grid-data-view-mutation'
import useSetViewAsDefaultMutation from '../data/use-set-view-as-default-mutation'
import useUpdateGridDataViewMutation from '../data/use-update-grid-data-view-mutation'
import ViewEditModal, { ViewEditParams } from './view-edit-modal'
import ViewNewModal, { NewViewParams } from './view-new-modal'
import ViewOptionsContextMenu from './view-options-context-menu'
import ViewSaveAsModal, { ViewSaveAsParams } from './view-save-as-modal'
import ViewShareModal from './view-share-modal'

type Views = ViewListItem[] | GridDataView[]
type View = ViewListItem | GridDataView

type ViewOptionsMenuProps = {
  open: boolean
  isViewDirty: boolean
  userGroupRef?: string
  views?: Views | undefined
  defaultViewRef: string | undefined | null
  datasets?: GridDataDataset[]
  assetTypes?: StaticOptionsResponse['asset_types']
  currentView: View | undefined
  portfolioRef?: string
  assetType?: string
  onViewSelect: (viewRef: string) => void
  onDatasetSelect: (datasetRef: string) => void
  createSaveViewBody: () => GridDataViewUpdateBody | undefined
  createSaveAsViewBody: (params: ViewSaveAsParams) => GridDataViewCreateBody | undefined
  createEditViewBody: (params: ViewEditParams) => GridDataViewUpdateBody | undefined
  onViewSaveAsSuccess: (data: any) => void
  onViewDeleteSuccess: (deletedViewRef: string) => void
  onClose: () => void
}

type ContextMenuState = {
  element: HTMLElement
  view: View
}

function ViewOptionsMenu(props: ViewOptionsMenuProps) {
  const {
    open,
    isViewDirty,
    userGroupRef,
    views = [],
    defaultViewRef,
    datasets,
    assetTypes,
    currentView,
    portfolioRef,
    assetType = null,
    createSaveViewBody,
    createSaveAsViewBody,
    createEditViewBody,
    onViewSelect,
    onDatasetSelect,
    onClose,
    onViewSaveAsSuccess,
    onViewDeleteSuccess,
  } = props

  const { t } = useTranslation('portfolio')

  const newViewModal = useOpenState()
  const editViewModal = useOpenState()
  const shareViewModal = useOpenState()
  const saveAsViewModal = useOpenState()
  const deleteViewModal = useOpenState()

  const createView = useCreateGridDataViewMutation()
  const updateView = useUpdateGridDataViewMutation()
  const setViewAsDefault = useSetViewAsDefaultMutation()
  const deleteView = useDeleteGridDataViewMutation()

  const [newView, setNewView] = useState<NewViewParams | null>(null)
  const [optionsVisible, setOptionsVisible] = useState(true)
  const [quickSearch, setQuickSearch] = useState('')
  const [activeView, setActiveView] = useState<ContextMenuState | undefined>()

  const quickSearchRef = useRef<HTMLTextAreaElement>(null)
  const viewListRef = useRef<HTMLUListElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const currentViewRef = currentView?.view_ref

  const canModify = !!currentView?.can_modify
  const canSave = canModify && isViewDirty && !!currentViewRef

  const showSave = canModify
  const showSaveAsBadge = !!newView || (isViewDirty && !canModify)

  const filteredViews = views.filter((view) => {
    return view.view_name.toLocaleLowerCase().includes(quickSearch.toLocaleLowerCase())
  })

  const myViews: { owner: Group; views: ViewListItem[] }[] = []
  const tenantViews: { owner: Group; views: ViewListItem[] }[] = []
  const otherViews: { owner: Group; views: ViewListItem[] }[] = []

  const viewsByGroup = filteredViews.reduce(
    (acc, view) => {
      const owner = view.group_owner
      const item = acc[owner.group_ref] || {
        owner,
        views: [],
      }

      item.views.push(view)
      acc[owner.group_ref] = item

      return acc
    },
    {} as { [groupRef: string]: { owner: Group; views: ViewListItem[] } }
  )
  for (const value of Object.values(viewsByGroup)) {
    if (value.owner.group_ref === userGroupRef) {
      myViews.push(value)
    } else if (value.owner.group_type === 'tenant') {
      tenantViews.push(value)
    } else {
      otherViews.push(value)
    }
  }

  const showMyViews = !!myViews.length || !!newView

  useEffect(() => {
    if (currentViewRef) {
      setNewView(null)
    }
  }, [currentViewRef])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  function handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        handleEscape()
        break
      case 'ArrowDown':
        handleArrowDown()
        break
      case 'ArrowUp':
        handleArrowUp()
        break
    }
  }

  function handleEscape() {
    handleClose()
  }

  function handleArrowDown() {
    if (viewListRef.current && document.activeElement === quickSearchRef.current) {
      quickSearchRef.current?.blur()
      const firstChild = viewListRef.current.querySelector('li')

      if (firstChild) {
        firstChild.focus()
      }
    }
  }

  function handleArrowUp() {
    const lastChild = viewListRef.current && viewListRef.current.lastChild

    if (lastChild && document.activeElement === lastChild) {
      quickSearchRef.current?.focus()
    }
  }

  function handleClickAway(event: MouseEvent | TouchEvent) {
    if (document.querySelector('#root')?.contains(event.target as Node)) {
      handleClose()
    }
  }

  function handleViewSelect(viewRef: string) {
    onViewSelect(viewRef)
    handleClose()
  }

  function handleNewViewSubmit(params: NewViewParams) {
    onDatasetSelect(params.datasetRef)
    setNewView(params)
    handleClose()
  }

  function handleClose() {
    setActiveView(undefined)
    setOptionsVisible(true)
    setQuickSearch('')
    onClose()
  }

  function handleOptionsMenuOpen(event: React.MouseEvent<HTMLElement>, view: ViewListItem) {
    setActiveView({ element: event.currentTarget, view })
  }

  function handleOptionsMenuClose() {
    const isAnyModalOpen =
      deleteViewModal.isOpen || saveAsViewModal.isOpen || editViewModal.isOpen || shareViewModal.isOpen

    if (isAnyModalOpen) {
      return
    }
    // This setTimeout is here because it seems the ...ViewModal.isOpen gives false faster than the UI is updated
    // and for that reason for a split second the activeView is reseted
    // and the data for the current view is shown befor the modal closes.
    setTimeout(() => {
      setActiveView(undefined)
    }, 200)
  }

  function handleCurrentViewOptionClick(callback: () => void) {
    callback()
    setActiveView(undefined)
  }

  function handleViewSave() {
    const viewRef = activeView?.view.view_ref || currentViewRef
    const body = createSaveViewBody()

    if (!body || !viewRef) {
      return
    }

    updateView.mutate(
      { viewRef, body },
      {
        onSuccess: () => {
          saveAsViewModal.close()
          queryClient.invalidateQueries(['portfolio', portfolioRef, 'options'])
          queryClient.invalidateQueries(['transaction', portfolioRef, 'options'])
          queryClient.invalidateQueries(['asset_type', assetType, 'options'])
          queryClient.invalidateQueries(['views'], { exact: false })
        },
      }
    )
  }

  function handleSetViewAsDefault() {
    const viewRef = activeView?.view.view_ref || currentViewRef

    if (!viewRef) {
      return
    }

    setViewAsDefault.mutate(
      { viewRef, assetType },
      {
        onSuccess: () => {
          setActiveView(undefined)
          queryClient.invalidateQueries(['portfolio', portfolioRef, 'options'])
          queryClient.invalidateQueries(['transaction', portfolioRef, 'options'])
          queryClient.invalidateQueries(['asset_type', assetType, 'options'])
          queryClient.invalidateQueries(['views'], { exact: false })
        },
      }
    )
  }

  function handleViewDelete() {
    const viewRef = activeView?.view.view_ref || currentViewRef

    if (!viewRef) {
      return
    }

    deleteView.mutate(
      { viewRef },
      {
        onSuccess: async () => {
          handleDeleteViewModalClose()
          setActiveView(undefined)
          onViewDeleteSuccess(viewRef)
          queryClient.invalidateQueries(['views'], { exact: false })
        },
      }
    )
  }

  function handleViewSaveAs(params: ViewSaveAsParams) {
    const body = createSaveAsViewBody(params)

    if (!body) {
      return
    }

    createView.mutate(
      { body },
      {
        onSuccess: ({ data }) => {
          saveAsViewModal.close()
          queryClient.invalidateQueries(['views'], { exact: false })
          onViewSaveAsSuccess(data)
        },
      }
    )
  }

  function handleEditViewSubmit(params: ViewEditParams) {
    const viewRef = activeView?.view.view_ref || currentViewRef

    if (!viewRef) {
      return
    }

    const body = createEditViewBody(params)

    if (!body) {
      return
    }

    updateView.mutate(
      { viewRef, body },
      {
        onSuccess: () => {
          editViewModal.close()
          setActiveView(undefined)
          queryClient.invalidateQueries(['portfolio', portfolioRef, 'options'])
          queryClient.invalidateQueries(['transaction', portfolioRef, 'options'])
          queryClient.invalidateQueries(['asset_type', assetType, 'options'])
          queryClient.invalidateQueries(['views'], { exact: false })
        },
      }
    )
  }

  function handleSaveViewModalClose() {
    createView.reset()
    saveAsViewModal.close()
  }

  function handleDeleteViewModalClose() {
    deleteView.reset()
    deleteViewModal.close()
  }

  if (!open) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={(event) => handleClickAway(event)}>
      <Stack
        sx={{
          minWidth: 300,
          top: 0,
          right: 0,
          backgroundColor: 'gray.main',
          position: 'absolute',
          zIndex: 2,
          height: '100%',
          overflowY: 'auto',
          boxShadow:
            '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
        }}
        ref={menuRef}
      >
        <Stack direction="row" sx={{ px: 1, pt: 1 }}>
          <MenuItemLabel label={t('views')} sx={{ px: 2 }} />
          <IconButton onClick={handleClose} sx={{ width: 24, height: 24, m: 1 }}>
            <Close fontSize="small" sx={{ color: 'gray.300' }} />
          </IconButton>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
          <Typography variant="body1" sx={{ px: 2, py: 1 }}>
            {currentView?.view_name || newView?.viewName || t('unsaved_view')}
          </Typography>
          {!!currentView && (
            <Chip
              title="ViewRef"
              size="small"
              label={
                <Typography fontSize="14px" color="gray.700">
                  {currentView.view_ref}
                </Typography>
              }
            />
          )}
        </Stack>

        <List sx={{ flexDirection: 'column', display: optionsVisible ? 'flex' : 'none', px: 1, pb: 2 }}>
          <MenuItemButton label={t('common:new')} Icon={Add} onClick={newViewModal.open} />
          {showSave && (
            <MenuItemButton
              label={t('common:save')}
              disabled={!canSave}
              showBadge={canSave}
              Icon={Save}
              onClick={() => handleCurrentViewOptionClick(handleViewSave)}
            />
          )}
          <MenuItemButton
            label={t('common:save_as')}
            showBadge={showSaveAsBadge}
            Icon={SaveAs}
            onClick={() => handleCurrentViewOptionClick(saveAsViewModal.open)}
          />
          {showSave && (
            <MenuItemButton
              label={t('common:reset')}
              Icon={Replay}
              disabled={!currentViewRef || !canSave}
              onClick={() => {
                if (currentViewRef) {
                  handleViewSelect(currentViewRef)
                }
              }}
            />
          )}
        </List>

        <Divider />

        <QuickSearchField
          ref={quickSearchRef}
          autoFocus
          placeholder={t('search_views')}
          onValueChange={setQuickSearch}
          sx={{ px: 1, pt: 3, pb: 1 }}
        />

        <MenuList ref={viewListRef} sx={{ minHeight: '400px', overflow: 'auto', px: 1, ':focus': { outline: 'none' } }}>
          {showMyViews && <MenuItemLabel label={t('my_views')} sx={{ px: 2 }} />}
          {!!newView && <MenuItemButton label={newView.viewName} selected />}
          {myViews.map((views) => [
            views.views.map((view) => (
              <MenuItemButton
                key={view.view_ref}
                label={view.view_name}
                selected={currentViewRef === view.view_ref}
                active={activeView?.view.view_ref === view.view_ref}
                description={defaultViewRef === view.view_ref ? t('default_view') : undefined}
                onClick={() => handleViewSelect(view.view_ref)}
                onOptionsClick={(event) => handleOptionsMenuOpen(event, view)}
              />
            )),
          ])}

          {!!tenantViews.length &&
            tenantViews.map((views) => [
              <MenuItemLabel key={views.owner.group_ref} label={t('company_views')} sx={{ px: 2, pt: 2 }} />,
              views.views.map((view) => (
                <MenuItemButton
                  key={view.view_ref}
                  label={view.view_name}
                  selected={currentViewRef === view.view_ref}
                  active={activeView?.view.view_ref === view.view_ref}
                  description={defaultViewRef === view.view_ref ? t('default_view') : undefined}
                  onClick={() => handleViewSelect(view.view_ref)}
                  onOptionsClick={(event) => handleOptionsMenuOpen(event, view)}
                />
              )),
            ])}

          {!!otherViews.length &&
            otherViews.map((views) => [
              <MenuItemLabel key={views.owner.group_ref} label={views.owner.group_name} sx={{ px: 2, pt: 2 }} />,
              views.views.map((view) => (
                <MenuItemButton
                  key={view.view_ref}
                  label={view.view_name}
                  selected={currentViewRef === view.view_ref}
                  active={activeView?.view.view_ref === view.view_ref}
                  description={defaultViewRef === view.view_ref ? t('default_view') : undefined}
                  onClick={() => handleViewSelect(view.view_ref)}
                  onOptionsClick={(event) => handleOptionsMenuOpen(event, view)}
                />
              )),
            ])}
        </MenuList>

        <ViewOptionsContextMenu
          anchorEl={activeView?.element}
          canModify={!!activeView?.view.can_modify}
          onEditClick={editViewModal.open}
          onShareClick={shareViewModal.open}
          onSetAsDefaultClick={handleSetViewAsDefault}
          onDeleteClick={deleteViewModal.open}
          onClose={handleOptionsMenuClose}
        />

        <ViewNewModal
          open={newViewModal.isOpen}
          datasets={datasets || []}
          assetTypes={assetTypes}
          onSubmit={handleNewViewSubmit}
          onClose={newViewModal.close}
        />
        <ConfirmationModal
          error={deleteView.error}
          isLoading={deleteView.isLoading}
          open={deleteViewModal.isOpen}
          onCloseButtonClick={handleDeleteViewModalClose}
          onConfirmButtonClick={handleViewDelete}
          confirmButtonText={t('common:delete')}
          title={t('common:delete')}
        >
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey={'common:are_you_sure_you_want_to_delete'}
              values={{ subject: activeView?.view.view_name || currentView?.view_name }}
              components={{ bold: <strong /> }}
            />
          </Typography>
        </ConfirmationModal>
        <ViewSaveAsModal
          open={saveAsViewModal.isOpen}
          initialName={activeView?.view.view_name || currentView?.view_name || newView?.viewName || ''}
          initialAssetTypes={
            activeView?.view.view_options?.asset_types || currentView?.view_options?.asset_types || newView?.assetTypes
          }
          assetTypes={assetTypes}
          error={createView.error}
          isLoading={createView.isLoading}
          onSubmit={handleViewSaveAs}
          onClose={handleSaveViewModalClose}
        />
        <ViewEditModal
          open={editViewModal.isOpen}
          viewRef={activeView?.view.view_ref || currentViewRef}
          currentName={activeView?.view.view_name || currentView?.view_name || ''}
          currentOwner={activeView?.view.group_owner || currentView?.group_owner}
          initialAssetTypes={activeView?.view.view_options?.asset_types || currentView?.view_options?.asset_types}
          assetTypes={assetTypes}
          isLoading={updateView.isLoading}
          onSubmit={handleEditViewSubmit}
          onClose={editViewModal.close}
        />
        <ViewShareModal
          open={shareViewModal.isOpen}
          viewRef={activeView?.view.view_ref || currentViewRef}
          onClose={shareViewModal.close}
        />
      </Stack>
    </ClickAwayListener>
  )
}

export default ViewOptionsMenu
