const idGenerator = (function* () {
  let i = 0
  while (true) {
    yield i
    i += 1
  }
})()

export function newId(): number {
  return idGenerator.next().value
}

export function newLocalKey(): string {
  const id = idGenerator.next().value
  return `local_key_${id}`
}
