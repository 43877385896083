import { ParsedGridColumnOption } from '../../../services/data/grid-data-parsing'
import {
  AssetCategory,
  DataDocType,
  StaticHeading,
  StaticResponse,
} from '../../../services/data/types/asset-static-data'
import { DatapointType, DatapointValue } from '../../../services/data/types/datapoint'
import { GridViewColumn } from '../../../services/data/types/grid-data-view'
import { DateTimeIso } from '../../../utils/dates'

export type ParsedAssetGridData = {
  headings: ParsedAssetGridHeading[]
  rows: ParsedAssetGridRow[]
  columnsOptions: ParsedAssetColumnOption[]
  shownColumnsCount: number
  originalHeadings: StaticHeading[]
}

export type ParsedAssetColumnOption = {
  columnName: string
  datapointRef: string
  datapointName: string
  datapointType: DatapointType
  assetCategory: AssetCategory | undefined
  classificationId?: number
  isHidden: boolean
}

export type ParsedAssetGridHeading = {
  key: string
  title: string
  sourceName?: string
  canEdit: boolean
  datadocType?: DataDocType | null
  canAutogenerate: boolean
  width: number | undefined
  decimalPlaces: number | undefined
  canMoveLeft: boolean
  canMoveRight: boolean
  meta?: StaticHeading
}

export type ParsedAssetGridRow = {
  rowKey: string
  cells: ParsedAssetGridCell[]
}

export type ParsedAssetGridCell = {
  cellKey: string
  headingKey: string

  value?: DatapointValue
  displayAs?: string
  displayRaw?: boolean
  asOnDate?: Date
  shadowedValue?: DatapointValue
  shadowedDisplayAs?: string
  assetRef: string
  assetDesc: string
  canEdit: boolean
  modifiedAt?: DateTimeIso
  modifiedBy?: string
  modifiedByDisplay?: string
  datadocType?: DataDocType | null
  canAutogenerate: boolean
  decimalPlaces: number | undefined
  meta?: StaticHeading
}

export function parseAssetStaticData(
  data?: StaticResponse,
  columns?: GridViewColumn[] | null
): ParsedAssetGridData | null {
  if (!data) {
    return null
  }

  if (columns) {
    data.headings.sort((a, b) => {
      const aIndex = columns.findIndex((column) => column.datapoint_ref === a.datapoint_ref)
      const bIndex = columns.findIndex((column) => column.datapoint_ref === b.datapoint_ref)

      if (aIndex === -1) {
        return 1
      }

      if (bIndex === -1) {
        return -1
      }

      if (aIndex > bIndex) {
        return 1
      }

      return -1
    })
  }

  const columnsState = new ViewColumnsState(columns)

  const visibleHeadings = getVisibleHeadings(data.headings, columnsState)

  const filteredData = {
    ...data,
    headings: visibleHeadings,
    rows: data.rows,
  }

  const headings = parseAssetStaticHeadings(filteredData, columnsState)
  const rows = parseAssetStaticRows(data, headings)

  let shownColumnsCount = 0

  const columnsOptions = data.headings
    .map((heading) => {
      const column = columnsState.getColumn(heading.datapoint_ref)
      const isHidden = !columnsState.isColumnVisible(heading.datapoint_ref)

      if (!isHidden) {
        shownColumnsCount += 1
      }

      const option: ParsedGridColumnOption = {
        columnName: column?.name || heading.datapoint_name,
        datapointRef: heading.datapoint_ref,
        datapointName: heading.datapoint_name,
        datapointType: heading.datapoint_type,
        classificationId: heading.classification_id,
        assetCategory: heading.asset_category,
        isHidden,
      }
      return option
    })
    .sort((a, b) => a.columnName.localeCompare(b.columnName))

  return {
    headings,
    rows,
    columnsOptions,
    shownColumnsCount,
    originalHeadings: data.headings,
  }
}

function parseAssetStaticHeadings(data: StaticResponse, columnsState: ViewColumnsState): ParsedAssetGridHeading[] {
  const firstHeading: ParsedAssetGridHeading = {
    key: 'heading_asset_description',
    title: '',
    canEdit: false,
    canAutogenerate: false,
    canMoveLeft: false,
    canMoveRight: false,
    width: undefined,
    decimalPlaces: undefined,
  }

  const parsedHeadings: ParsedAssetGridHeading[] = data.headings.map((heading, index) => {
    const column = columnsState.getColumn(heading.datapoint_ref)

    return {
      key: `heading_${heading.datapoint_ref}`,
      title: heading.datapoint_name,
      sourceName: heading.source_dataset_name,
      canEdit: heading.can_edit,
      meta: heading,
      datadocType: heading.datadoc_type,
      canAutogenerate: !!heading.can_autogenerate,
      canMoveLeft: index > 0,
      canMoveRight: index < data.headings.length - 1,
      width: column?.width || undefined,
      decimalPlaces: column?.decimal_places ?? undefined,
    } satisfies ParsedAssetGridHeading
  })

  return [firstHeading, ...parsedHeadings]
}

function parseAssetStaticRows(data: StaticResponse, headings: ParsedAssetGridHeading[]): ParsedAssetGridRow[] {
  return data.rows.map((row) => {
    const cells: ParsedAssetGridCell[] = headings.map((heading, index) => {
      if (index === 0) {
        return {
          cellKey: 'cell_asset_description',
          headingKey: heading.key,
          value: { String: row.asset_desc },
          canEdit: false,
          canAutogenerate: false,
          assetRef: row.asset_ref,
          assetDesc: row.asset_desc,
          decimalPlaces: undefined,
        } satisfies ParsedAssetGridCell
      }

      const cellValue = row.datapoints[heading.meta?.datapoint_ref || '']

      if (cellValue) {
        return {
          cellKey: `cell_${heading.meta?.datapoint_ref}_${row.asset_ref}`,
          headingKey: heading.key,
          value: cellValue.value,
          displayAs: cellValue.display_as,
          shadowedValue: cellValue.shadowed_value,
          shadowedDisplayAs: cellValue.shadowed_display_as,
          modifiedAt: cellValue.modified_at,
          modifiedBy: cellValue.modified_by,
          modifiedByDisplay: cellValue.modified_by_display,
          asOnDate: cellValue.as_on_date,
          canEdit: heading.canEdit,
          datadocType: heading.datadocType,
          canAutogenerate: heading.canAutogenerate,
          assetRef: row.asset_ref,
          assetDesc: row.asset_desc,
          decimalPlaces: heading.decimalPlaces,
          meta: heading.meta,
        } satisfies ParsedAssetGridCell
      }

      return {
        cellKey: `cell_${heading.meta?.datapoint_ref}_${row.asset_ref}`,
        headingKey: heading.key,
        canEdit: heading.canEdit,
        datadocType: heading.datadocType,
        canAutogenerate: heading.canAutogenerate,
        assetRef: row.asset_ref,
        assetDesc: row.asset_desc,
        decimalPlaces: heading.decimalPlaces,
        meta: heading.meta,
      } satisfies ParsedAssetGridCell
    })

    return {
      rowKey: `row_${row.asset_ref}`,
      cells,
    } satisfies ParsedAssetGridRow
  })
}

function getVisibleHeadings(headings: StaticHeading[], columnsState: ViewColumnsState) {
  // show all headings when view doesn't have columns
  // just a shortcut to speed things up
  if (columnsState.isEmpty) {
    return headings
  }
  return headings.filter((heading) => columnsState.isColumnVisible(heading.datapoint_ref))
}

class ViewColumnsState {
  private state: { [datapointRef: string]: GridViewColumn }
  readonly isEmpty: boolean

  constructor(viewColumns: GridViewColumn[] | null | undefined) {
    this.state = {}
    this.isEmpty = true

    if (viewColumns) {
      for (const column of viewColumns) {
        this.state[column.datapoint_ref] = column
        this.isEmpty = false
      }
    }
  }

  getColumn(datapointRef: string): GridViewColumn | undefined {
    return this.state[datapointRef]
  }

  isColumnVisible(datapointRef: string): boolean {
    // show all columns when view doesn't have columns
    if (this.isEmpty) {
      return true
    }

    // do not show when column is not on view
    const column = this.getColumn(datapointRef)
    if (!column) {
      return false
    }

    // show columns that are not hidden
    return column.position !== 'h'
  }
}
